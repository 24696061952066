<template>
  <div class="workbench">
    <div class="contain">
      <img src="@/assets/image/bg-pic.png" alt="" />
      <span>功能待上线......</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'System',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.workbench {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .contain {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      width: 282px;
      height: 216px;
    }
    span {
      color: #999;
    }
  }
}
</style>